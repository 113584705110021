html,
body,
#root {
  margin: 0px;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

